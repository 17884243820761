

















































// Core
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Debounce from '@/decorators/Debounce'

// Types
import { ProjectsActions, ProjectsMutations } from '@store/projects/types'

// Interfaces
import { Paginate, RequestParams } from '@store/interfaces'
import { Role } from '@/store/common/Interface'
import { Project } from '@store/projects/interfaces'
import { UsersState } from '@/store/users/interfaces'
import { ElForm } from 'element-ui/types/form'

// Modules
const NSProjects = namespace('projectsModule')
const NSUsers = namespace('usersModule')

@Component({
  name: 'DialogSelectProject',

  components: {
    'v-project-list': () => import('./ProjectList.vue'),
  },
})
export default class DialogSelectProjectComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @PropSync('roleId', Number) private _roleId!: number

  @Prop(Function)
  private handleConnectUserToProject!: (projectId: number) => Promise<void>

  @NSUsers.State((state: UsersState) => state.roles)
  private roles!: Role[]

  @NSProjects.Mutation(ProjectsMutations.M_CLEAR_PROJECTS)
  private clearProjects!: () => void

  @NSProjects.Action(ProjectsActions.A_GET_PROJECTS)
  private fetchProjects!: (params?: RequestParams) => Promise<Paginate<Project>>

  private search = ''
  private loading: boolean = false

  private form: { roleId: number | null } = {
    roleId: null,
  }

  protected rules = {
    roleId: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  async created() {
    this.loading = true
    this.clearProjects()
    await this.fetchProjects()
    this.loading = false
  }

  @Debounce(1000)
  private async handleChangeSearch() {
    if (this.search.length >= 3 || this.search.length === 0) {
      this.loading = true
      this.clearProjects()
      await this.fetchProjects({ search: this.search })
      this.loading = false
    }
  }

  private _handleConnectUserToProject(projectId: number) {
    const form = this.$refs.form as ElForm

    form.validate((isValid) => {
      if (isValid) {
        this._roleId = this.form.roleId as number
        this.loading = true
        this.handleConnectUserToProject(projectId)
          .finally(() => this.loading = false)
      }
    })
  }

  private handleClose() {
    const form = this.$refs.form as ElForm

    form.resetFields()

    this._visible = false
  }
}
